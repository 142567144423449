<template>
    <VueEasyLightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    />
</template>

<script>

import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    name: 'EditorImagePopup',
    props: ['imgs','index' ,'visibleProps'],
    emits: ['hide'],
    components: {
        VueEasyLightbox,
    },
    watch: {
        visibleProps: function(value) {
            this.visible = value
        }
    },
    data: function() {
        return {
            visible: false,
            imgs: "",
            index: 0,
        }
    },
    computed: {
    },
    methods: {
        showSingle() {
            this.show()
        },

        show() {
            this.visible = true
        },

        handleHide() {
            this.visible = false
            this.$emit("hide", true)
        }

    },
}
</script>

<style scoped>
</style>
